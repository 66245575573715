export enum eventCtw {
  SetQueryParameters = 'SetQueryParameters', // ctw-disrupt
  NearbyHome = 'NearbyHome', // ctw-nearby
  ContactForm = 'ContactForm', // page contact
  LegalNotices = 'LegalNotices', //page de cdgu (conditions générales d'utilisation)
  Gdpr = 'Gdpr',
  GoogleStreetView = 'GoogleStreetView', // redirect to Google street view
  ShowMaps = "ShowMaps" // permet d'afficher les maps

}

// events for ctw-journey-planner (itineraires)
export enum eventCtwJourneyPlanner {
  FullScreenAutocomp = 'FullScreenAutocomp',
  TripPlannerSearch = 'TripPlannerSearch',
  ScheduleLine = 'ScheduleLine',
  TripPlannerSearchArrival = 'TripPlannerSearchArrival',
  TripPlannerSearchDeparture = 'TripPlannerSearchDeparture',
  Print = 'Print'
}

// events for ctw-journey-planner-print (itineraires-imprimer)
export enum eventCtwJourneyPlannerPrint {
  Accessibility = 'Accessibility'
}

// events for ctw-member
export enum eventCtwMember {
  LoginSigninForm = 'LoginSigninForm',
  LoggedOut = 'LoggedOut',
  MemberHome = 'MemberHome',
  ConfigureAlerts = 'ConfigureAlerts',
  SessionExpired = 'SessionExpired',
}

// events for ctw-mobility-account 
export enum eventCtwMobilityAccount {
  ProfilesList = 'ProfilesList', // context permettant d'afficher la liste des profils m-tickets
  ServicesList = 'ServicesList', // context permettant d'afficher la liste des conditions des services
  MyPortfolio = 'MyPortfolio', // context permettant d'afficher le composant d'achat de titres de transports
  MyBankingDetails = 'MyBankingDetails', // context permettant d'afficher le composant de gestion des coordonnées bancaires SEPA
  MyUsageDirectDebit = 'MyUsageDirectDebit' // context permettant d'afficher le composant echeancier de prélevement
}

// events for ctw-taxi
export enum eventCtwTaxi {
  TaxiHome = 'TaxiHome',
  TaxiSubscription = 'TaxiSubscription',
  TaxiConfirmation = 'TaxiConfirmation',
  TaxiBooking = 'TaxiBooking'
}

// events for ctw-taxi-map
export enum eventCtwTaxiMap {
  TaxiSolutions = 'TaxiSolutions',
  TaxiSolutionDetail = 'TaxiSolutionDetail',
  TaxiBookingDetail = 'TaxiBookingDetail'
}

// events for ctw-schedule
export enum eventCtwSchedule {
  SchedulePhysicalStop = 'SchedulePhysicalStop',
  ScheduleLogicalStop = 'ScheduleLogicalStop',
  ScheduleForm = 'ScheduleForm'
}

// events for ctw-tod
export enum eventCtwTod {
  TodSolutions = 'TodSolutions',
  TodReservationDetail = 'TodReservationDetail',
  TodReservationCancel = 'TodReservationCancel',
  LegalNotices = 'LegalNotices',
  TodInfo = 'TodInfo',
}

// events for ctw-tod-home
export enum eventCtwTodHome {
  TodHome = 'TodHome',
  TodBooking = 'TodBooking',
  TodInfo = 'TodInfo',
  ContactForm = 'ContactForm',
}

// events for ctw-eshop
export enum eventCtwEshop {
  PersonalData = 'PersonalData', // commander une carte
  ProfileSelector = 'ProfileSelector', // commander une carte
  Attachments = 'Attachments', // commander une carte
  Basket = 'Basket', // voir mon panier
  AddCard = 'AddCard', // assicier une carte
  CardsList = 'CardsList', // liste des cartes (accueil)
  AddTitle = 'AddTitle', // ajouter un titre (Commander une carte)
  DeliveryAddress = 'DeliveryAddress', // Livraison (Commander une carte)
  Payment = 'Payment', // Paiement (Commander une carte)
  Confirmation = 'Confirmation', // Confirmation (Commander une carte)
  LostCard = 'LostCard', // Déclarer une carte perdue
}

// events for ctw-nearby
export enum eventCtwNearby {
  NearbyPopup = 'NearbyPopup',
}