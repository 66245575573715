<nav class="skip-links" role="navigation" aria-label="Liens d'évitement">
  <ul class="skip-links-button-container">
    <li>
      <button role="link">Accéder au contenu</button>
    </li>
  </ul>
  <button class="skip-links-close-button" aria-label="Fermer les liens d'évitement">×</button>
</nav>

<header>
  <mat-toolbar [ngClass]="[isMapDisplayed ? 'topbar-map' : 'fixedTop']">
    <app-header
      role="banner"
      (sidenavToggle)="sidenav.toggle()"
      (sidenavClose)="sidenav.close()"
      [ngClass]="[isMapDisplayed ? 'topbar-map' : 'topbar']"
    ></app-header>
    <mat-button-toggle
      *ngIf="!sidenav.opened"
      value="menu"
      (click)="sidenav.toggle()"
    >
      <div class="menuIconText">
        <mat-icon>menu</mat-icon>
        <span translate>raccourcis.menu</span>
      </div></mat-button-toggle
    >
    <button
      *ngIf="sidenav.opened"
      mat-mini-fab
      aria-label="Fermer le menu"
      (click)="sidenav.toggle()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar>
</header>

<main
  [ngClass]="[
    isMapDisplayed ? 'main-container-carto-full-height' : 'main-container'
  ]"
  class="atos-main-app"
>
  <mat-sidenav-container class="main-container-carto">
    <mat-sidenav #sidenav mode="over">
      <app-side-nav-list (sidenavClose)="sidenav.close()"></app-side-nav-list>
    </mat-sidenav>
    <mat-sidenav-content role="main">
      <app-bandeau-alertes></app-bandeau-alertes>
      <div class="disrupt-major">
        <ctw-disrupt-major
          [context]="context"
          [disruptionId]="disruptionId"
          (raisedEvent)="raisedEvent($event)"
        >
        </ctw-disrupt-major>
      </div>
      <div [ngClass]="[isMapDisplayed ? 'main-container-carto' : 'container']">
        <app-breadcrumb
          [hidden]="isMapDisplayed || showOnHomepage"
        ></app-breadcrumb>
        <app-popup-information></app-popup-information>
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</main>
<footer>
  <app-footer-dynamic [hidden]="isMapDisplayed"></app-footer-dynamic>
  <app-footer-fix [hidden]="isMapDisplayed"></app-footer-fix>
  <app-footer-map [hidden]="!isMapDisplayed"></app-footer-map>
</footer>
<header>
  <mat-toolbar
    class="fixedBottom"
    color="white"
    role="heading"
    aria-label="header fix en bas de page"
    fxHide.gt-sm
  >
    <app-menu-footer
      (sidenavToggle)="sidenav.toggle()"
      (sidenavClose)="sidenav.close()"
      style="flex: 1"
    ></app-menu-footer>
  </mat-toolbar>
</header>
